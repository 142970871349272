'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var CustomError = function CustomError(message) {
  _classCallCheck(this, CustomError);

  if (Error.hasOwnProperty('captureStackTrace')) {
    Error.captureStackTrace(this, CustomError);
  } else {
    this.stack = new Error(message).stack;
  }
};

if (typeof Object.setPrototypeOf === 'function') {
  Object.setPrototypeOf(CustomError.prototype, Error.prototype);
} else {
  CustomError.prototype = Object.create(Error.prototype, {
    constructor: { value: CustomError }
  });
}

var ApiError = exports.ApiError = function (_CustomError) {
  _inherits(ApiError, _CustomError);

  function ApiError(response) {
    _classCallCheck(this, ApiError);

    var _this = _possibleConstructorReturn(this, (ApiError.__proto__ || Object.getPrototypeOf(ApiError)).call(this, response.message));

    _this.name = _this.constructor.name;
    _this.message = response.message;
    _this.errorCode = response.errorCode;
    _this.type = response.type;
    return _this;
  }

  return ApiError;
}(CustomError);

var UnavailableApiError = exports.UnavailableApiError = function (_ApiError) {
  _inherits(UnavailableApiError, _ApiError);

  function UnavailableApiError() {
    _classCallCheck(this, UnavailableApiError);

    return _possibleConstructorReturn(this, (UnavailableApiError.__proto__ || Object.getPrototypeOf(UnavailableApiError)).apply(this, arguments));
  }

  return UnavailableApiError;
}(ApiError);

var TimeoutError = exports.TimeoutError = function (_UnavailableApiError) {
  _inherits(TimeoutError, _UnavailableApiError);

  function TimeoutError() {
    _classCallCheck(this, TimeoutError);

    return _possibleConstructorReturn(this, (TimeoutError.__proto__ || Object.getPrototypeOf(TimeoutError)).apply(this, arguments));
  }

  return TimeoutError;
}(UnavailableApiError);

var HttpApiError = exports.HttpApiError = function (_ApiError2) {
  _inherits(HttpApiError, _ApiError2);

  function HttpApiError() {
    _classCallCheck(this, HttpApiError);

    return _possibleConstructorReturn(this, (HttpApiError.__proto__ || Object.getPrototypeOf(HttpApiError)).apply(this, arguments));
  }

  return HttpApiError;
}(ApiError);

var HttpUnauthorized = exports.HttpUnauthorized = function (_HttpApiError) {
  _inherits(HttpUnauthorized, _HttpApiError);

  function HttpUnauthorized() {
    _classCallCheck(this, HttpUnauthorized);

    return _possibleConstructorReturn(this, (HttpUnauthorized.__proto__ || Object.getPrototypeOf(HttpUnauthorized)).apply(this, arguments));
  }

  return HttpUnauthorized;
}(HttpApiError);

var HttpNotFoundError = exports.HttpNotFoundError = function (_HttpApiError2) {
  _inherits(HttpNotFoundError, _HttpApiError2);

  function HttpNotFoundError() {
    _classCallCheck(this, HttpNotFoundError);

    return _possibleConstructorReturn(this, (HttpNotFoundError.__proto__ || Object.getPrototypeOf(HttpNotFoundError)).apply(this, arguments));
  }

  return HttpNotFoundError;
}(HttpApiError);

var RequestEntityTooLarge = exports.RequestEntityTooLarge = function (_HttpApiError3) {
  _inherits(RequestEntityTooLarge, _HttpApiError3);

  function RequestEntityTooLarge() {
    _classCallCheck(this, RequestEntityTooLarge);

    return _possibleConstructorReturn(this, (RequestEntityTooLarge.__proto__ || Object.getPrototypeOf(RequestEntityTooLarge)).apply(this, arguments));
  }

  return RequestEntityTooLarge;
}(HttpApiError);

var HttpInternalServerError = exports.HttpInternalServerError = function (_HttpApiError4) {
  _inherits(HttpInternalServerError, _HttpApiError4);

  function HttpInternalServerError() {
    _classCallCheck(this, HttpInternalServerError);

    return _possibleConstructorReturn(this, (HttpInternalServerError.__proto__ || Object.getPrototypeOf(HttpInternalServerError)).apply(this, arguments));
  }

  return HttpInternalServerError;
}(HttpApiError);

var HttpServiceUnavailable = exports.HttpServiceUnavailable = function (_HttpApiError5) {
  _inherits(HttpServiceUnavailable, _HttpApiError5);

  function HttpServiceUnavailable() {
    _classCallCheck(this, HttpServiceUnavailable);

    return _possibleConstructorReturn(this, (HttpServiceUnavailable.__proto__ || Object.getPrototypeOf(HttpServiceUnavailable)).apply(this, arguments));
  }

  return HttpServiceUnavailable;
}(HttpApiError);

var getHttpApiErrorClass = exports.getHttpApiErrorClass = function getHttpApiErrorClass(statusCode) {
  return HttpErrorMapping[statusCode] || HttpApiError;
};

var HttpErrorMapping = exports.HttpErrorMapping = {
  '401': HttpUnauthorized,
  '404': HttpNotFoundError,
  '413': RequestEntityTooLarge,
  '500': HttpInternalServerError,
  '503': HttpServiceUnavailable
};

var CustomApiError = exports.CustomApiError = function (_ApiError3) {
  _inherits(CustomApiError, _ApiError3);

  function CustomApiError() {
    _classCallCheck(this, CustomApiError);

    return _possibleConstructorReturn(this, (CustomApiError.__proto__ || Object.getPrototypeOf(CustomApiError)).apply(this, arguments));
  }

  return CustomApiError;
}(ApiError);

var AuthLoginFailed = exports.AuthLoginFailed = function (_CustomApiError) {
  _inherits(AuthLoginFailed, _CustomApiError);

  function AuthLoginFailed() {
    _classCallCheck(this, AuthLoginFailed);

    return _possibleConstructorReturn(this, (AuthLoginFailed.__proto__ || Object.getPrototypeOf(AuthLoginFailed)).apply(this, arguments));
  }

  return AuthLoginFailed;
}(CustomApiError);

var AuthUsernameNotExist = exports.AuthUsernameNotExist = function (_CustomApiError2) {
  _inherits(AuthUsernameNotExist, _CustomApiError2);

  function AuthUsernameNotExist() {
    _classCallCheck(this, AuthUsernameNotExist);

    return _possibleConstructorReturn(this, (AuthUsernameNotExist.__proto__ || Object.getPrototypeOf(AuthUsernameNotExist)).apply(this, arguments));
  }

  return AuthUsernameNotExist;
}(CustomApiError);

var AuthInvalidUsername = exports.AuthInvalidUsername = function (_CustomApiError3) {
  _inherits(AuthInvalidUsername, _CustomApiError3);

  function AuthInvalidUsername() {
    _classCallCheck(this, AuthInvalidUsername);

    return _possibleConstructorReturn(this, (AuthInvalidUsername.__proto__ || Object.getPrototypeOf(AuthInvalidUsername)).apply(this, arguments));
  }

  return AuthInvalidUsername;
}(CustomApiError);

var CustomErrorMapping = exports.CustomErrorMapping = {
  // @todo: only example, change these later
  '101': AuthLoginFailed,
  '103': AuthInvalidUsername
};

var getCustomApiErrorClass = exports.getCustomApiErrorClass = function getCustomApiErrorClass(errorCode) {
  return CustomErrorMapping[errorCode] || CustomApiError;
};

var getErrorInstance = exports.getErrorInstance = function getErrorInstance(errorCode) {
  var errorClass = CustomErrorMapping[errorCode] || HttpErrorMapping[errorCode] || ApiError;
  return new errorClass({ errorCode: errorCode });
};