'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  position: relative;\n  box-shadow: inset 0 0 0 2px\n    ', ';\n  border-radius: 3px;\n  width: ', 'px;\n  height: ', 'px;\n'], ['\n  position: relative;\n  box-shadow: inset 0 0 0 2px\n    ', ';\n  border-radius: 3px;\n  width: ', 'px;\n  height: ', 'px;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  position: absolute;\n  left: 0;\n  top: 0;\n  pointer-events: none;\n'], ['\n  position: absolute;\n  left: 0;\n  top: 0;\n  pointer-events: none;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  position: absolute;\n  left: 0;\n  top: 0;\n  appearance: none;\n  width: ', 'px;\n  height: ', 'px;\n  &:focus {\n    outline: none;\n  }\n'], ['\n  position: absolute;\n  left: 0;\n  top: 0;\n  appearance: none;\n  width: ', 'px;\n  height: ', 'px;\n  &:focus {\n    outline: none;\n  }\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rgIcons = require('rg-icons');

var _ = require('..');

var _ramda = require('ramda');

var _theme = require('./theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var Checkbox = function (_React$Component) {
  _inherits(Checkbox, _React$Component);

  function Checkbox(props) {
    _classCallCheck(this, Checkbox);

    var _this = _possibleConstructorReturn(this, (Checkbox.__proto__ || Object.getPrototypeOf(Checkbox)).call(this, props));

    _this.handleChange = function () {
      var onChange = _this.props.onChange;
      var isOpen = _this.state.isOpen;

      _this.setState({ isOpen: !isOpen }, function () {
        return onChange && onChange();
      });
    };

    _this.state = {
      isOpen: false
    };
    return _this;
  }

  _createClass(Checkbox, [{
    key: 'render',
    value: function render() {
      var _props = (0, _ramda.omit)(['name', 'value', 'setOpen', 'toggle', 'onChange', 'checked', 'disabled', 'type', 'controlled'], this.props);
      var _props2 = this.props,
          checked = _props2.checked,
          disabled = _props2.disabled,
          open = _props2.open,
          controlled = _props2.controlled;
      var isOpen = this.state.isOpen;


      return React.createElement(
        StyledBox,
        _props,
        React.createElement(StyledCheckbox, {
          type: 'checkbox',
          onChange: this.handleChange,
          checked: checked || false,
          disabled: disabled || false
        }),
        !controlled && (isOpen || checked) || controlled && checked ? React.createElement(StyledIcon, {
          fill: _theme.colors.green,
          name: 'checkbox',
          size: _theme.fontSizes[2] + 'px'
        }) : null
      );
    }
  }]);

  return Checkbox;
}(React.Component);

var StyledBox = (0, _styledComponents2.default)(_.Box)(_templateObject, function (props) {
  return props.open ? 'transparent' : props.borderColor || _theme.colors.border;
}, _theme.fontSizes[2], _theme.fontSizes[2]);

var StyledIcon = (0, _styledComponents2.default)(_rgIcons.Icon)(_templateObject2);

var StyledCheckbox = _styledComponents2.default.input(_templateObject3, _theme.fontSizes[2], _theme.fontSizes[2]);

exports.default = Checkbox;