'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  padding-left: 30px;\n  display: ', ';\n'], ['\n  padding-left: 30px;\n  display: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  z-index: 1000;\n  cursor: pointer;\n  min-width: 400px;\n  width: 100%;\n  max-height: 200px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  border: solid 1px ', ';\n  border-radius: 4px;\n  margin-top: 4px;\n\n  ::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n'], ['\n  z-index: 1000;\n  cursor: pointer;\n  min-width: 400px;\n  width: 100%;\n  max-height: 200px;\n  overflow-y: scroll;\n  overflow-x: hidden;\n  border: solid 1px ', ';\n  border-radius: 4px;\n  margin-top: 4px;\n\n  ::-webkit-scrollbar {\n    width: 10px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n\n  :hover {\n    background-color: ', ';\n  }\n\n  min-width: 400px;\n'], ['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n\n  :hover {\n    background-color: ', ';\n  }\n\n  min-width: 400px;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  text-transform: capitalize;\n'], ['\n  text-transform: capitalize;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _recompose = require('recompose');

var _withToggle = require('../unstable/withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('../unstable/withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _rgIcons = require('rg-icons');

var _ = require('../');

var _utils = require('utils');

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _lodash = require('lodash.isequal');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AutoComplete = function (_React$Component) {
  _inherits(AutoComplete, _React$Component);

  function AutoComplete(props) {
    _classCallCheck(this, AutoComplete);

    var _this = _possibleConstructorReturn(this, (AutoComplete.__proto__ || Object.getPrototypeOf(AutoComplete)).call(this, props));

    _initialiseProps.call(_this);

    var multiple = _this.props.multiple;


    var initialState = {
      keyword: '',
      searchResult: [],
      showResult: false,
      currentSelected: multiple ? [] : undefined
    };

    _this.state = initialState;
    return _this;
  }

  _createClass(AutoComplete, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var selectedOptions = this._getSelectedOptions();
      this.setState({
        currentSelected: selectedOptions[0]
      });
      this._handleOnchange('');
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var prevData = prevProps.data;
      var _props = this.props,
          data = _props.data,
          value = _props.value,
          open = _props.open,
          multiple = _props.multiple;


      if (!(0, _lodash2.default)(data, prevData) || value !== prevProps.value) {
        var selectedOptions = this._getSelectedOptions();

        if (!multiple) {
          this.setState({
            currentSelected: selectedOptions[0]
          });
        }
        this._handleOnchange(this.state.keyword);
      }

      if (open !== prevProps.open) {
        if (open) {
          this._focusToSearchInput();
        }
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          keyword = _state.keyword,
          currentSelected = _state.currentSelected;
      var _props2 = this.props,
          name = _props2.name,
          placeholder = _props2.placeholder,
          open = _props2.open;


      var inputValue = Array.isArray(currentSelected) ? '' : currentSelected ? currentSelected.label : '';

      return React.createElement(
        'div',
        { ref: this.props.onRef },
        React.createElement(
          _.Flex,
          null,
          React.createElement(
            _.Box,
            { w: this.props.width },
            React.createElement(
              _.Relative,
              null,
              React.createElement(
                _.Absolute,
                { left: '0.625rem', top: '52%', transform: 'translateY(-50%)' },
                React.createElement(_rgIcons.Icon, { name: 'search' })
              ),
              React.createElement(StyledInput, {
                type: 'text',
                placeholder: placeholder,
                readonly: true,
                show: !open,
                onClick: this._onClick,
                value: inputValue
              }),
              React.createElement(StyledInput, {
                ref: function (_ref) {
                  function ref(_x) {
                    return _ref.apply(this, arguments);
                  }

                  ref.toString = function () {
                    return _ref.toString();
                  };

                  return ref;
                }(function (ref) {
                  _this2.searchElmRef = ref;
                }),
                type: 'text',
                name: name,
                value: keyword,
                placeholder: placeholder,
                onChange: function onChange(e) {
                  var keyword = e.target.value;
                  _this2.setState({ keyword: keyword });
                  _this2._handleOnchange(keyword);
                },
                show: open,
                autoComplete: 'off'
              }),
              open && this._renderResult()
            )
          )
        )
      );
    }
  }]);

  return AutoComplete;
}(React.Component);

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this._focusToSearchInput = function () {
    var inputElm = _reactDom2.default.findDOMNode(_this3.searchElmRef);
    inputElm && inputElm.focus();
  };

  this._onClick = function () {
    _this3.props.setOpen(true);
  };

  this._onClickOutside = function () {
    _this3.props.setOpen(false);
  };

  this._handleOnOptionSelected = function (value, label) {
    var _props3 = _this3.props,
        name = _props3.name,
        onChange = _props3.onChange,
        multiple = _props3.multiple,
        setOpen = _props3.setOpen;


    if (!multiple) {
      _this3._handleOnchange('');
    }

    _this3.setState(function (_ref4) {
      var keyword = _ref4.keyword,
          currentSelected = _ref4.currentSelected;

      var newSelected = { value: value, label: label };

      if (multiple) {
        var _currSelected = Array.isArray(currentSelected) ? currentSelected : [];

        var isItemAlreadySelected = _currSelected.some(function (item) {
          return item.value === value;
        });

        newSelected = isItemAlreadySelected ? _currSelected : [].concat(_toConsumableArray(_currSelected), [newSelected]);
      }

      return {
        keyword: multiple ? keyword : '',
        showResult: false,
        currentSelected: newSelected
      };
    }, function () {
      setOpen(false);
      onChange(name, value);
    });
  };

  this._handleOnchange = function (value) {
    var data = _this3.props.data;

    var keyword = value;

    var searchResult = keyword !== '' && keyword !== 'All' ? data.filter(function (item) {
      return item.label === 'All' || item.label.toLowerCase().includes(keyword.toLowerCase());
    }) : data;
    _this3.setState({ searchResult: searchResult });
  };

  this._renderResult = function () {
    var _state2 = _this3.state,
        keyword = _state2.keyword,
        searchResult = _state2.searchResult;
    var colors = _.theme.colors;


    return React.createElement(
      StyledAutoCompleteResultBox,
      { bg: colors.white, is: _.Absolute, mt: '2px' },
      (0, _utils.ensureArray)(searchResult).map(function (item) {
        var currentSplitLength = 0;

        return React.createElement(
          StyledResultFlex,
          {
            py: '10px',
            px: '20px',
            onClick: function onClick() {
              return _this3._handleOnOptionSelected(item.value, item.label);
            },
            key: item.value },
          (0, _utils.ensureString)(item.label, '').toLowerCase().split(keyword).map(function (charSplit, charIndex) {
            var itemLabel = (0, _utils.ensureString)(item.label, '');
            var itemSplitKeywordLength = itemLabel.toLowerCase().split(keyword).length;
            var itemSplitLength = itemLabel.split('').length;
            var keywordLength = keyword.split('').length;

            var charSplitLength = charSplit.split('').length;
            var currSplitLength = currentSplitLength;
            var endSplitLength = currSplitLength + charSplitLength;
            var endSplitKeyLength = endSplitLength + keywordLength;
            currentSplitLength = currentSplitLength + charSplitLength + keywordLength;

            return React.createElement(
              _.Flex,
              { key: charSplit + String(charIndex) },
              React.createElement(
                _.Flex,
                null,
                itemLabel.charAt(currSplitLength) === ' ' && React.createElement(
                  _.Text,
                  null,
                  '\xA0'
                )
              ),
              React.createElement(
                _.Flex,
                null,
                itemLabel.substring(currSplitLength, endSplitLength)
              ),
              React.createElement(
                _.Flex,
                null,
                itemLabel.charAt(endSplitLength - 1) === ' ' && React.createElement(
                  _.Text,
                  null,
                  '\xA0'
                )
              ),
              React.createElement(
                _.Flex,
                { bg: colors.green },
                itemLabel.substring(endSplitLength, endSplitKeyLength)
              )
            );
          })
        );
      })
    );
  };

  this._getSelectedOptions = function () {
    var _props4 = _this3.props,
        _props4$value = _props4.value,
        value = _props4$value === undefined ? '' : _props4$value,
        data = _props4.data;


    var selectedOptions = value !== '' ? (0, _utils.ensureArray)(data).filter(function (item) {
      return item.value === (0, _utils.ensureString)(value, '');
    }) : [];

    return selectedOptions;
  };
};

var StyledInput = (0, _styledComponents2.default)(_.Input)(_templateObject, function (props) {
  return props.show ? 'inline-block' : 'none';
});

var StyledAutoCompleteResultBox = (0, _styledComponents2.default)(_.Box)(_templateObject2, function (_ref2) {
  var theme = _ref2.theme;
  return theme.colors.alto;
});

var StyledResultFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject3, function (props) {
  return props.theme.colors.softBrown;
}, function (props) {
  return props.theme.colors.alto;
}, function (props) {
  return props.theme.colors.alto;
}, function (props) {
  return props.theme.colors.greySmoke;
});

var StyledCapitalizeText = (0, _styledComponents2.default)(_.Text)(_templateObject4);

exports.default = (0, _recompose.compose)(_withToggle2.default, (0, _recompose.withProps)(function (_ref3) {
  var setOpen = _ref3.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  };
}), _withClickOutside2.default)(AutoComplete);