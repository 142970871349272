"use strict";

const createModels = (models, configs) => {
  const modifiedModels = {};

  const modelKeys = Object.keys(models);

  modelKeys.forEach(key => {
    const currentModel = models[key];
    modifiedModels[key] = currentModel(configs);
  });

  return modifiedModels;
};

module.exports = createModels;