'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _isNumeric = require('./isNumeric');

var _isNumeric2 = _interopRequireDefault(_isNumeric);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ensureInt = function ensureInt(num, defaultValue) {
  if (!defaultValue) {
    return (0, _isNumeric2.default)(num) ? typeof num === 'number' ? num : parseInt(num, 10) : 0;
  }

  return (0, _isNumeric2.default)(num) ? typeof num === 'number' ? num : parseInt(num, 10) : ensureInt(defaultValue);
};

exports.default = ensureInt;