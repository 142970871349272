"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
class ManifestWrapper {

  constructor(configs, assets) {
    this.assets = assets;
  }

  getUrl(name) {
    return this.assets[name];
  }
}

exports.default = ManifestWrapper;