'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _axios = require('axios');

var _axios2 = _interopRequireDefault(_axios);

var _ApiErrors = require('./ApiErrors');

var ApiErrors = _interopRequireWildcard(_ApiErrors);

var _querystring = require('querystring');

var _querystring2 = _interopRequireDefault(_querystring);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var delayedPromise = function delayedPromise(response) {
  return new Promise(function (resolve, reject) {
    setTimeout(function () {
      return response.errorCode ? reject(ApiErrors.getErrorInstance(response.errorCode)) : resolve(response.data);
    }, 250);
  });
};

var getFullUrl = function getFullUrl(url, params) {
  if (!params) {
    return url;
  }
  return url + '?' + _querystring2.default.stringify(params);
};

var getResponseData = function getResponseData(response) {
  var data = response.data;

  if (data && !data.data) {
    return data;
  } else if (data && !!data.data) {
    return data.data;
  } else {
    return null;
  }
};

var checkIsTimeout = function checkIsTimeout(request) {
  var timeout = request.timeout;

  if (!timeout) {
    return false;
  } else {
    return timeout;
  }
};

var getErrorData = function getErrorData(response) {
  var data = response.data;

  if (!data) {
    return response;
  } else {
    return data;
  }
};

var MAX_RETRY = 3;
var DELAY_RETRY = 1000;

var ApiClient = function () {
  function ApiClient(configs, mockData) {
    var _this = this;

    _classCallCheck(this, ApiClient);

    // Because we have bunch of projects using this, the default value will prevent the error to occur
    var proxyEndpoint = configs.proxyEndpoint,
        token = configs.token,
        onAuthError = configs.onAuthError,
        _configs$country = configs.country,
        country = _configs$country === undefined ? 'id' : _configs$country,
        _configs$defaultHeade = configs.defaultHeaders,
        defaultHeaders = _configs$defaultHeade === undefined ? {} : _configs$defaultHeade;

    this.instance = _axios2.default.create({
      baseURL: proxyEndpoint
    });

    this.token = token;
    this.defaultHeaders = defaultHeaders;
    this.country = country;
    this.CancelToken = _axios2.default.CancelToken;
    this._currentRetry = {};

    this.instance.interceptors.response.use(function (response) {
      var config = response.config;

      _this._setRetryCount(config, 0);
      return getResponseData(response);
    }, function (error) {
      var errorInstance = void 0;
      var request = error.request,
          response = error.response,
          message = error.message,
          config = error.config;

      var isTimedOut = checkIsTimeout(request);

      if (isTimedOut) {
        errorInstance = new ApiErrors.TimeoutError();
      } else {
        var currentRetry = _this._getRetryCount(config);
        if (token && currentRetry < MAX_RETRY && response && (response.status === 401 || response.status === 403)) {
          _this._setRetryCount(config, currentRetry + 1);
          return new Promise(function (resolve) {
            setTimeout(function () {
              resolve(onAuthError(error));
            }, DELAY_RETRY);
          });
        }
        var errorData = getErrorData(response);
        if (errorData.errorCode) {
          errorInstance = new (ApiErrors.getCustomApiErrorClass(errorData.errorCode))({
            errorCode: errorData.errorCode,
            message: errorData.message,
            type: 'custom'
          });
        } else if (response) {
          errorInstance = new (ApiErrors.getHttpApiErrorClass(response.status))({
            errorCode: response.status,
            message: '[' + config.url + '] ' + message,
            type: 'http'
          });
        } else {
          errorInstance = new ApiErrors.UnavailableApiError();
        }
      }
      return Promise.reject(errorInstance);
    });

    this.mockData = mockData;
  }

  _createClass(ApiClient, [{
    key: '_getMockData',
    value: function _getMockData(url, params, data) {
      var method = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'get';

      var fullUrl = getFullUrl(url, params);
      var mockedData = this.mockData[method][fullUrl] || this.mockData[method][url];
      if (mockedData && typeof mockedData === 'function') {
        if (method === 'post' || method === 'put') {
          return mockedData(data);
        } else {
          return mockedData(params);
        }
      }
      return mockedData;
    }
  }, {
    key: '_getCurrentStateKey',
    value: function _getCurrentStateKey(config) {
      return '' + config.url + (config.params ? JSON.stringify(config.params) : '');
    }
  }, {
    key: '_getRetryCount',
    value: function _getRetryCount(config) {
      var stateKey = this._getCurrentStateKey(config);
      return typeof this._currentRetry[stateKey] !== 'undefined' ? this._currentRetry[stateKey] : 0;
    }
  }, {
    key: '_setRetryCount',
    value: function _setRetryCount(config, value) {
      var stateKey = this._getCurrentStateKey(config);
      this._currentRetry[stateKey] = value;
    }
  }, {
    key: 'setToken',
    value: function setToken(token) {
      this.token = token;
    }
  }, {
    key: 'request',
    value: function request(config) {
      var url = config.url,
          params = config.params,
          data = config.data,
          method = config.method;

      var mockedData = this._getMockData(url, params, data, method);
      if (mockedData) {
        return delayedPromise(mockedData);
      }

      var headers = _extends({}, this.defaultHeaders, config.headers, {
        country: this.country,
        Authorization: this.token
      });

      if (headers['with-auth'] === true || headers['with-auth'] === 'true') {
        delete headers.Authorization;
      }

      return this.instance.request(_extends({}, config, {
        headers: headers
      }));
    }
  }, {
    key: 'get',
    value: function get(url, params) {
      var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.request(_extends({
        method: 'get',
        url: url,
        params: params
      }, config));
    }
  }, {
    key: 'post',
    value: function post(url, data) {
      var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.request(_extends({
        data: data,
        method: 'post',
        url: url
      }, config));
    }
  }, {
    key: 'put',
    value: function put(url, data) {
      var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.request(_extends({
        data: data,
        method: 'put',
        url: url
      }, config));
    }
  }, {
    key: 'delete',
    value: function _delete(url, data) {
      var config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return this.request(_extends({
        data: data,
        method: 'delete',
        url: url
      }, config));
    }
  }]);

  return ApiClient;
}();

exports.default = ApiClient;