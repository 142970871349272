'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMonthFromDate = undefined;

var _constants = require('./constants');

var getMonthFromDate = function getMonthFromDate(_ref) {
  var date = _ref.date,
      output = _ref.output,
      _ref$monthName = _ref.monthName,
      monthName = _ref$monthName === undefined ? 'compact' : _ref$monthName;

  // this function accept YYYY-MM-DD format
  var monthRaw = date.slice(5, 7);

  var processedMonth = output === 'number' ? monthRaw : _constants.MONTHS_LIST[monthName][parseInt(monthRaw, 10) - 1];

  return processedMonth;
};

exports.getMonthFromDate = getMonthFromDate;