'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var breakpoints = exports.breakpoints = [32, 48, 64, 80];

var space = exports.space = [0, 4, 8, 16, 32, 64, 128];

var fontSizes = exports.fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72, 96];

var weights = exports.weights = [400, 600, 700];

var colors = exports.colors = {
  black: '#000',
  white: '#fff',
  abbey: '#444447',
  alto: '#dbdbdb',
  tango: '#ee6723',
  sunsetOrange: '#ff4d4d',
  pistachio: '#80c50d',
  yellowGreen: '#b8e986',
  sanjuan: '#345678',
  fiord: '#3a4a67',
  rhino: '#273753',
  biscay: '#1e3675',
  cloudBurst: '#1d2c46',
  alabaster: '#fbfbfb',
  softBrown: '#fafafb',
  black10: 'rgba(0, 0, 0, 0.1)',
  gray: '#858585',
  mercury: '#e8e8e8',
  //grey
  athensGrey: '#efeff5',
  shuttleGray: '#60697f',
  //white
  catskillWhite: '#f0f3f8',
  catskillWhite2: '#f1f4f9',
  //yellow
  capeHoney: '#fdebb5',
  amber: '#fbbd0a',
  //blue
  matisse: '#345678',
  matisse80: 'rgba(52, 86, 119, .8)',
  matisseDark: '#2e4d6a',
  havelockBlue: '#609ddb',
  prussianBlue: '#002A54',
  steelBlue: '#5b7ab4',
  blumine20: 'rgba(31, 114, 85, 0.2)',
  whiteIce: '#E0EEFA',
  //misc ~
  frenchGray: '#c2c5ca',
  whisper: '#fafafc',
  shadow: 'rgba(7,96,115,.07)',
  softBlue: '#5c9ed6',
  strongBlue: '#305772',
  silver: '#f2f2f2',
  strongSilver: '#ddd',
  silverChalice: '#acacac',
  silverChalice50: 'rgba(176, 176, 176, 0.5)',
  lynch: '#717a9f',
  chesnutRose: '#d25367',
  celery: '#9cca59',
  anzac: '#e1a73f',
  atoll: 'rgba(7, 96, 115, 0.07)',
  martinique: 'rgba(50, 50, 93, 0.1)',
  //Alias
  get red() {
    return this.sunsetOrange;
  },
  get green() {
    return this.pistachio;
  },
  get blue() {
    return this.havelockBlue;
  },
  get border() {
    return this.alto;
  },
  get primaryText() {
    return this.abbey;
  },
  get primary() {
    return this.blue;
  },
  get warn() {
    return this.tango;
  },
  get danger() {
    return this.red;
  },
  get valid() {
    return this.green;
  }
};

var radius = exports.radius = 4;
var font = exports.font = 'Source Sans Pro, -apple-system, BlinkMacSystemFont, sans-serif';
var monospace = exports.monospace = '"SF Mono", "Roboto Mono", Menlo, monospace';

exports.default = {
  breakpoints: breakpoints,
  space: space,
  fontSizes: fontSizes,
  weights: weights,
  font: font,
  monospace: monospace,
  radius: radius,
  colors: colors
};