'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _theme = require('./theme');

var _util = require('./util');

var _grid = require('./grid');

var _CheckboxBase = require('./CheckboxBase');

var _CheckboxBase2 = _interopRequireDefault(_CheckboxBase);

var _SelectBase = require('./SelectBase');

var _SelectBase2 = _interopRequireDefault(_SelectBase);

var _IncreamentBase = require('./IncreamentBase');

var _IncreamentBase2 = _interopRequireDefault(_IncreamentBase);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var numberOrString = (0, _propTypes.oneOfType)([_propTypes.number, _propTypes.string]);
var bold = function bold(props) {
  return (0, _util.idx)('weights.1', props.theme);
};

var components = [{
  name: 'Button',
  type: 'button',
  props: {
    f: 1,
    m: 0,
    pl: 3,
    pr: 3,
    pt: 2,
    pb: 2,
    bg: 'blue',
    color: 'white'
  },
  style: function style(props) {
    return {
      cursor: 'pointer',
      fontWeight: _theme.weights[2],
      textTransform: 'uppercase',
      borderRadius: (0, _util.px)(props.theme.radius),
      fontFamily: 'inherit',
      WebkitFontSmoothing: 'antialiased',
      display: 'inline-block',
      verticalAlign: 'middle',
      textAlign: 'center',
      textDecoration: 'none',
      boxShadow: 'none',
      outline: 'none',
      border: 0,
      appearance: 'none',
      '&:hover': {
        opacity: 0.8
      },
      '&:focus': {
        outline: 0
      },
      '&:active': {
        boxShadow: 'inset 0 0 0 999px ' + (0, _util.lighten)(1 / 4)
      },
      '&:disabled': {
        opacity: 1 / 4
      }
    };
  }
}, {
  name: 'ButtonOutline',
  type: 'Button',
  props: {
    bg: 'transparent',
    color: 'blue'
  },
  style: function style(props) {
    return {
      boxShadow: 'inset 0 0 0 1px',
      '&:hover': {
        opacity: 0.8
      },
      '&:active': {
        boxShadow: 'inset 0 0 0 1px'
      }
    };
  }
}, {
  name: 'ButtonTransparent',
  type: 'Button',
  props: {
    bg: 'transparent'
  },
  style: function style(props) {
    return {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      padding: 0,
      color: props.theme.colors.cornflowerBlue,
      '&:hover': {
        opacity: 0.8
      },
      '&:active': {
        opacity: 1
      }
    };
  }
}, {
  name: 'Label',
  type: 'label',
  props: {
    f: 2,
    mb: 1
  },
  style: function style(props) {
    return {
      fontWeight: _theme.weights[1]
    };
  }
}, {
  name: 'Input',
  type: 'input',
  props: {
    type: 'text',
    f: 'inherit',
    py: 2,
    px: 3,
    m: 0,
    w: 1,
    color: 'inherit'
  },
  style: function style(props) {
    return {
      fontFamily: 'inherit',
      display: 'inline-block',
      verticalAlign: 'middle',
      appearance: 'none',
      border: 0,
      borderRadius: (0, _util.px)(props.theme.radius),
      boxShadow: '0 0 0 1px ' + (0, _util.statusColor)(props),
      '&:focus': {
        outline: 'none',
        boxShadow: '0 0 0 1px ' + (0, _util.statusColorFocus)(props)
      },
      '&:disabled': {
        cursor: 'not-allowed',
        backgroundColor: '#e8e8e8'
      }
    };
  }
}, {
  name: 'Textarea',
  type: 'textarea',
  props: {
    py: 2,
    px: 3,
    m: 0,
    w: 1,
    color: 'inherit'
  },
  style: function style(props) {
    return {
      fontFamily: 'inherit',
      fontSize: 'inherit',
      border: 0,
      boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('border'),
      borderRadius: (0, _util.px)(props.theme.radius),
      appearance: 'none',
      '&:focus': {
        outline: 'none',
        boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('blue')
      },
      '&:disabled': {
        opacity: 1 / 4
      }
    };
  }
}, {
  name: 'Card',
  type: 'div',
  props: {
    bg: 'white'
  },
  style: function style(props) {
    return {
      overflow: 'hidden',
      boxShadow: '0 15px 35px ' + (0, _util.color)(props)('shadow') + ', 0 5px 15px rgba(0,0,0,.07)',
      borderRadius: (0, _util.px)(props.theme.radius)
    };
  }
}, {
  name: 'Overlay',
  type: 'div',
  props: {
    p: 3
  },
  style: function style(props) {
    return {
      width: '100vw',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      outline: 0,
      overflowX: props.open ? 'hidden' : 'inherit',
      overflowY: props.open ? 'auto' : 'inherit'
    };
  }
},
// TYPOGRAPHY
{
  name: 'Text',
  type: 'p',
  props: {
    m: 0
  },
  style: function style(props) {
    return Object.assign({
      fontWeight: props.bold ? bold(props) : (0, _util.idx)('weights.0', props.theme)
    }, (0, _util.align)(props), (0, _util.caps)(props));
  },
  propTypes: {
    left: _propTypes.bool,
    center: _propTypes.bool,
    right: _propTypes.bool,
    justify: _propTypes.bool,
    bold: _propTypes.bool,
    caps: _propTypes.bool
  }
}, {
  name: 'Span',
  type: 'span',
  props: {
    m: 0
  },
  style: function style(props) {
    return Object.assign({
      fontWeight: props.bold ? bold(props) : (0, _util.idx)('weights.0', props.theme)
    }, (0, _util.align)(props), (0, _util.caps)(props));
  },
  propTypes: {
    left: _propTypes.bool,
    center: _propTypes.bool,
    right: _propTypes.bool,
    justify: _propTypes.bool,
    bold: _propTypes.bool,
    caps: _propTypes.bool
  }
}, {
  name: 'Heading',
  type: 'Text',
  props: {
    is: 'h2',
    f: 5,
    m: 0
  },
  style: {
    lineHeight: 1.25
  },
  propTypes: {
    left: _propTypes.bool,
    center: _propTypes.bool,
    right: _propTypes.bool,
    justify: _propTypes.bool,
    bold: _propTypes.bool,
    caps: _propTypes.bool
  }
}, {
  name: 'Subhead',
  type: 'Heading',
  props: {
    is: 'h3',
    f: 4,
    m: 0
  },
  style: {}
}, {
  name: 'Small',
  type: 'Text',
  props: {
    is: 'small',
    f: 0
  },
  style: {}
}, {
  name: 'Lead',
  type: 'Text',
  props: {
    is: 'p',
    f: 3,
    m: 0
  },
  style: {
    lineHeight: 1.25
  }
}, {
  name: 'Pre',
  type: 'pre',
  props: {
    f: 1,
    m: 0
  },
  style: function style(props) {
    return {
      fontFamily: props.theme.monospace,
      overflow: 'auto'
    };
  }
}, {
  name: 'Code',
  type: 'code',
  props: {
    f: 1
  },
  style: function style(props) {
    return {
      fontFamily: props.theme.monospace
    };
  }
}, {
  name: 'Samp',
  type: 'Code',
  props: {
    is: 'samp'
  },
  style: {}
}, {
  name: 'Blockquote',
  type: 'Text',
  props: {
    is: 'blockquote',
    m: 0,
    f: 3
  },
  style: {}
}, {
  name: 'Measure',
  type: 'div',
  props: {},
  style: {
    maxWidth: '32em'
  }
}, {
  name: 'Truncate',
  type: 'Text',
  props: {},
  style: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
  }
}, {
  name: 'Radio',
  type: 'input',
  props: {
    type: 'radio',
    m: 0,
    mr: 2
  },
  style: function style(props) {
    return {
      appearance: 'none',
      width: (0, _util.px)((0, _util.idx)('fontSizes.3', props.theme)),
      height: (0, _util.px)((0, _util.idx)('fontSizes.3', props.theme)),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('border'),
      borderRadius: '50%',
      '&:hover': {
        boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('border')
      },
      '&:checked': {
        boxShadow: '0 0 0 1px ' + (0, _util.color)(props)('border'),
        backgroundColor: '' + (0, _util.color)(props)('primary'),
        border: '3px solid white'
      },
      '&:focus': {
        outline: 'none'
      }
    };
  }
}, {
  name: 'Fixed',
  type: 'div',
  props: {},
  style: function style(props) {
    return {
      position: 'fixed',
      top: props.top ? 0 : null,
      right: props.right ? 0 : null,
      bottom: props.bottom ? 0 : null,
      left: props.left ? 0 : null,
      zIndex: props.z
    };
  }
}, {
  name: 'Relative',
  type: 'div',
  props: {},
  style: function style(props) {
    return {
      position: 'relative',
      zIndex: props.z
    };
  }
}, {
  name: 'Absolute',
  type: 'div',
  props: {},
  style: function style(props) {
    return {
      position: 'absolute',
      top: props.top ? props.top : null,
      right: props.right ? props.right : null,
      bottom: props.bottom ? props.bottom : null,
      left: props.left ? props.left : null,
      zIndex: props.z ? props.z : null,
      transform: props.transform ? props.transform : null
    };
  }
}, {
  name: 'Link',
  type: 'a',
  props: {
    color: 'blue'
  },
  style: function style(props) {
    return {
      cursor: 'pointer',
      textDecoration: 'none'
    };
  }
}, {
  name: 'Badge',
  type: 'div',
  props: {
    f: 0,
    p: 1,
    ml: 1,
    mr: 1,
    color: 'white',
    bg: 'blue'
  },
  style: function style(props) {
    return {
      fontWeight: bold(props),
      display: 'inline-block',
      verticalAlign: 'middle',
      borderRadius: (0, _util.px)(props.theme.radius)
    };
  }
}, {
  name: 'Circle',
  type: 'Badge',
  props: {
    color: 'white',
    bg: 'blue'
  },
  style: function style(props) {
    return {
      textAlign: 'center',
      width: (0, _util.px)(props.size || 24),
      height: (0, _util.px)(props.size || 24),
      borderRadius: (0, _util.px)(99999)
    };
  }
}, {
  name: 'Image',
  type: 'img',
  props: {},
  style: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto'
  }
}, {
  name: 'BackgroundImage',
  type: 'div',
  props: {
    w: 1
  },
  style: function style(props) {
    return {
      backgroundImage: props.src ? 'url(' + props.src + ')' : 'none',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      height: 0,
      paddingBottom: (props.ratio || 3 / 4) * 100 + '%'
    };
  },
  propTypes: {
    src: _propTypes.string,
    ratio: _propTypes.number
  }
}, {
  name: 'Border',
  type: 'div',
  props: {},
  style: function style(props) {
    var w = (0, _util.px)(props.none ? 0 : props.borderWidth || 1);
    var borderWidth = !props.top && !props.right && !props.bottom && !props.left ? { borderWidth: w } : null;
    var directions = borderWidth ? null : {
      borderTopWidth: props.top ? w : 0,
      borderRightWidth: props.right ? w : 0,
      borderBottomWidth: props.bottom ? w : 0,
      borderLeftWidth: props.left ? w : 0
    };

    return Object.assign({
      borderStyle: 'solid',
      borderColor: (0, _util.color)(props)(props.color || 'gray'),
      borderRadius: props.radius ? (0, _util.px)(props.radius) : '0',
      color: 'inherit'
    }, borderWidth, directions);
  },
  propTypes: {
    top: _propTypes.bool,
    right: _propTypes.bool,
    bottom: _propTypes.bool,
    left: _propTypes.bool,
    none: _propTypes.bool,
    width: _propTypes.number,
    color: _propTypes.string
  }
}, {
  name: 'Checkbox',
  type: _CheckboxBase2.default,
  props: {
    m: 0,
    mr: 2
  },
  style: function style(props) {
    return {};
  }
}, {
  name: 'Select',
  type: _SelectBase2.default,
  props: {
    m: 0,
    w: 1,
    color: 'inherit',
    bg: 'white'
  },
  style: function style(props) {
    return {
      display: 'inline-block',
      verticalAlign: 'middle',
      select: {
        padding: (0, _util.px)((0, _util.idx)('space.2', props.theme)),
        paddingRight: (0, _util.px)((0, _util.idx)('space.4', props.theme)),
        boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('border'),
        borderRadius: (0, _util.px)(props.theme.radius),

        '&:focus': {
          boxShadow: 'inset 0 0 0 1px ' + (0, _util.color)(props)('blue')
        },
        '&:disabled': {
          backgroundColor: '#e8e8e8',
          cursor: 'not-allowed'
        }
      }
    };
  }
}, {
  name: 'Divider',
  type: 'hr',
  props: {
    mx: 0
  },
  style: function style(props) {
    return {
      border: 0,
      borderBottom: '1px solid',
      borderColor: (0, _util.color)(props)('border')
    };
  }
}, {
  name: 'Switch',
  type: 'div',
  props: {
    role: 'checkbox',
    color: 'blue'
  },
  style: function style(props) {
    return {
      position: 'relative',
      display: 'inline-flex',
      width: (0, _util.px)(70),
      height: (0, _util.px)(32),
      borderRadius: (0, _util.px)(9999),
      backgroundColor: props.checked ? (0, _util.color)(props)(props.color) : '#345678',
      transitionProperty: 'background-color',
      transitionDuration: '.2s',
      transitionTimingFunction: 'ease-out',
      userSelect: 'none',
      '&::before': {
        content: props.checked ? '"YES"' : '"NO"',
        position: 'absolute',
        lineHeight: 1,
        top: '50%',
        left: props.checked ? (0, _util.px)(10) : (0, _util.px)(38),
        color: (0, _util.color)(props)('white'),
        transform: 'translateY(-50%)'
      },
      '&::after': {
        content: '" "',
        width: (0, _util.px)(23),
        height: (0, _util.px)(23),
        margin: (0, _util.px)(4),
        borderRadius: (0, _util.px)(9999),
        transitionProperty: 'transform, color',
        transitionDuration: '.1s',
        transitionTimingFunction: 'ease-out',
        transform: props.checked ? 'translateX(38px)' : 'translateX(2px)',
        backgroundColor: (0, _util.color)(props)('white')
      }
    };
  }
}, {
  name: 'Increament',
  type: _IncreamentBase2.default,
  props: {
    type: 'text',
    f: 'inherit',
    p: 2,
    m: 0,
    color: 'inherit',
    w: '30px'
  },
  style: function style(props) {
    return {
      fontFamily: 'inherit',
      display: 'inline-block',
      verticalAlign: 'middle',
      appearance: 'none',
      textAlign: 'center',
      border: 0,
      boxShadow: '0 0 0 1px ' + (0, _util.statusColor)(props) + ' inset',
      '&:disabled': {
        opacity: 1 / 4
      }
    };
  }
}];

exports.default = components;