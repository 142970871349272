'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _recompose = require('recompose');

var withOpen = (0, _recompose.withState)('open', 'setOpen', false);
var withToggle = (0, _recompose.mapProps)(function (p) {
  return _extends({}, p, {
    toggle: function toggle(e) {
      if (e) e.preventDefault();
      p.setOpen(function (o) {
        return !o;
      });
    }
  });
});

exports.default = (0, _recompose.compose)(withOpen, withToggle);