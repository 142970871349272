'use strict';

exports.__esModule = true;
var breakpoints = exports.breakpoints = ['40em', '52em', '64em'];

var space = exports.space = [0, 4, 8, 16, 32, 64, 128, 256, 512];

var fontSizes = exports.fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 72];

exports.default = {
  breakpoints: breakpoints,
  space: space,
  fontSizes: fontSizes
};