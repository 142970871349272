"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var removeEmptyValuesFromObject = exports.removeEmptyValuesFromObject = function removeEmptyValuesFromObject(obj) {
  var newObj = {};

  Object.keys(obj).forEach(function (key) {
    if (obj[key] || [0, false].includes(obj[key])) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};