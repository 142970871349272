'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _recompose = require('recompose');

exports.default = (0, _recompose.compose)((0, _recompose.withHandlers)({
  handleEsc: function handleEsc(_ref) {
    var onPressEsc = _ref.onPressEsc,
        open = _ref.open;
    return function (e) {
      if (e.keyCode === 27 && open) {
        onPressEsc();
      }
    };
  }
}), (0, _recompose.lifecycle)({
  componentWillMount: function componentWillMount() {
    document.addEventListener('keydown', this.props.handleEsc, false);
  },
  componentWillUnmount: function componentWillUnmount() {
    document.removeEventListener('keydown', this.props.handleEsc, false);
  }
}));