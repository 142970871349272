'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createModels = exports.createClient = undefined;

var _createClient = require('./createClient');

var _createClient2 = _interopRequireDefault(_createClient);

var _createModels = require('./createModels');

var _createModels2 = _interopRequireDefault(_createModels);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

exports.createClient = _createClient2.default;
exports.createModels = _createModels2.default;