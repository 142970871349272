'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _recompose = require('recompose');

exports.default = (0, _recompose.compose)((0, _recompose.withHandlers)(function () {
  var elem = null;
  return {
    onRef: function onRef() {
      return function (ref) {
        return elem = ref;
      };
    },
    onWrapperBlur: function onWrapperBlur(_ref) {
      var onClickOutside = _ref.onClickOutside;
      return function (e) {
        if (!elem.contains(e.target)) {
          onClickOutside();
        }
      };
    }
  };
}), (0, _recompose.lifecycle)({
  componentDidMount: function componentDidMount() {
    document.addEventListener('mousedown', this.props.onWrapperBlur);
  },
  componentWillUnmount: function componentWillUnmount() {
    document.removeEventListener('mousedown', this.props.onWrapperBlur);
  }
}));