'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var formatDuration = function formatDuration(_ref) {
  var durationInMinutes = _ref.durationInMinutes,
      _ref$outputFormat = _ref.outputFormat,
      outputFormat = _ref$outputFormat === undefined ? 'string' : _ref$outputFormat;

  var hoursAsNumber = Math.floor(durationInMinutes / 60);
  var minutesAsNumber = Math.round(durationInMinutes - hoursAsNumber * 60);

  var leftPad = function leftPad(num, size) {
    return (1e15 + num + '').slice(-size);
  };

  var hoursAsStrings = leftPad(hoursAsNumber, 2);
  var minutesAsStrings = leftPad(minutesAsNumber, 2);

  var hours = outputFormat === 'number' ? hoursAsNumber : hoursAsStrings;
  var minutes = outputFormat === 'number' ? minutesAsNumber : minutesAsStrings;

  return { hours: hours, minutes: minutes };
};

exports.formatDuration = formatDuration;