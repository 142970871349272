"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ensureArray = function ensureArray(arr, defaultValue) {
  if (!defaultValue) {
    return Array.isArray(arr) && arr || [];
  }

  return Array.isArray(arr) && arr || ensureArray(defaultValue);
};

exports.default = ensureArray;