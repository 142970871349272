'use strict';

exports.__esModule = true;
exports.propTypes = exports.util = exports.theme = exports.themeGet = exports.complexStyle = exports.pseudoStyle = exports.responsiveStyle = exports.style = exports.buttonStyle = exports.colorStyle = exports.textStyle = exports.disabled = exports.active = exports.focus = exports.hover = exports.left = exports.bottom = exports.right = exports.top = exports.zIndex = exports.position = exports.backgroundImage = exports.backgroundRepeat = exports.backgroundPosition = exports.backgroundSize = exports.background = exports.boxShadow = exports.borderRadius = exports.borderColor = exports.borders = exports.borderLeft = exports.borderBottom = exports.borderRight = exports.borderTop = exports.border = exports.order = exports.alignSelf = exports.justifySelf = exports.flexBasis = exports.flex = exports.flexDirection = exports.flexWrap = exports.justifyContent = exports.alignContent = exports.alignItems = exports.ratio = exports.size = exports.minHeight = exports.maxHeight = exports.height = exports.maxWidth = exports.minWidth = exports.display = exports.letterSpacing = exports.fontWeight = exports.lineHeight = exports.textAlign = exports.fontFamily = exports.color = exports.bgColor = exports.textColor = exports.fontSize = exports.width = exports.space = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; // ESM syntax is supported.


var _util = require('./util');

Object.defineProperty(exports, 'style', {
  enumerable: true,
  get: function get() {
    return _util.style;
  }
});
Object.defineProperty(exports, 'responsiveStyle', {
  enumerable: true,
  get: function get() {
    return _util.responsiveStyle;
  }
});
Object.defineProperty(exports, 'pseudoStyle', {
  enumerable: true,
  get: function get() {
    return _util.pseudoStyle;
  }
});
Object.defineProperty(exports, 'complexStyle', {
  enumerable: true,
  get: function get() {
    return _util.complexStyle;
  }
});
Object.defineProperty(exports, 'themeGet', {
  enumerable: true,
  get: function get() {
    return _util.themeGet;
  }
});
Object.defineProperty(exports, 'theme', {
  enumerable: true,
  get: function get() {
    return _util.themeGet;
  }
});

var _styles = require('./styles');

var styles = _interopRequireWildcard(_styles);

var utilities = _interopRequireWildcard(_util);

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var space = styles.space,
    width = styles.width,
    fontSize = styles.fontSize,
    textColor = styles.textColor,
    bgColor = styles.bgColor,
    color = styles.color,
    fontFamily = styles.fontFamily,
    textAlign = styles.textAlign,
    lineHeight = styles.lineHeight,
    fontWeight = styles.fontWeight,
    letterSpacing = styles.letterSpacing,
    display = styles.display,
    minWidth = styles.minWidth,
    maxWidth = styles.maxWidth,
    height = styles.height,
    maxHeight = styles.maxHeight,
    minHeight = styles.minHeight,
    size = styles.size,
    ratio = styles.ratio,
    alignItems = styles.alignItems,
    alignContent = styles.alignContent,
    justifyContent = styles.justifyContent,
    flexWrap = styles.flexWrap,
    flexDirection = styles.flexDirection,
    flex = styles.flex,
    flexBasis = styles.flexBasis,
    justifySelf = styles.justifySelf,
    alignSelf = styles.alignSelf,
    order = styles.order,
    border = styles.border,
    borderTop = styles.borderTop,
    borderRight = styles.borderRight,
    borderBottom = styles.borderBottom,
    borderLeft = styles.borderLeft,
    borders = styles.borders,
    borderColor = styles.borderColor,
    borderRadius = styles.borderRadius,
    boxShadow = styles.boxShadow,
    background = styles.background,
    backgroundSize = styles.backgroundSize,
    backgroundPosition = styles.backgroundPosition,
    backgroundRepeat = styles.backgroundRepeat,
    backgroundImage = styles.backgroundImage,
    position = styles.position,
    zIndex = styles.zIndex,
    top = styles.top,
    right = styles.right,
    bottom = styles.bottom,
    left = styles.left,
    hover = styles.hover,
    focus = styles.focus,
    active = styles.active,
    disabled = styles.disabled,
    textStyle = styles.textStyle,
    colorStyle = styles.colorStyle,
    buttonStyle = styles.buttonStyle;
exports.space = space;
exports.width = width;
exports.fontSize = fontSize;
exports.textColor = textColor;
exports.bgColor = bgColor;
exports.color = color;
exports.fontFamily = fontFamily;
exports.textAlign = textAlign;
exports.lineHeight = lineHeight;
exports.fontWeight = fontWeight;
exports.letterSpacing = letterSpacing;
exports.display = display;
exports.minWidth = minWidth;
exports.maxWidth = maxWidth;
exports.height = height;
exports.maxHeight = maxHeight;
exports.minHeight = minHeight;
exports.size = size;
exports.ratio = ratio;
exports.alignItems = alignItems;
exports.alignContent = alignContent;
exports.justifyContent = justifyContent;
exports.flexWrap = flexWrap;
exports.flexDirection = flexDirection;
exports.flex = flex;
exports.flexBasis = flexBasis;
exports.justifySelf = justifySelf;
exports.alignSelf = alignSelf;
exports.order = order;
exports.border = border;
exports.borderTop = borderTop;
exports.borderRight = borderRight;
exports.borderBottom = borderBottom;
exports.borderLeft = borderLeft;
exports.borders = borders;
exports.borderColor = borderColor;
exports.borderRadius = borderRadius;
exports.boxShadow = boxShadow;
exports.background = background;
exports.backgroundSize = backgroundSize;
exports.backgroundPosition = backgroundPosition;
exports.backgroundRepeat = backgroundRepeat;
exports.backgroundImage = backgroundImage;
exports.position = position;
exports.zIndex = zIndex;
exports.top = top;
exports.right = right;
exports.bottom = bottom;
exports.left = left;
exports.hover = hover;
exports.focus = focus;
exports.active = active;
exports.disabled = disabled;
exports.textStyle = textStyle;
exports.colorStyle = colorStyle;
exports.buttonStyle = buttonStyle;


// node 10.0.0 export * as util from './src/util'
var util = exports.util = utilities;
var propTypes = exports.propTypes = {};

Object.keys(styles).forEach(function (key) {
  propTypes[key] = styles[key].propTypes;
});

exports.default = _extends({}, styles, { propTypes: propTypes });