'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _uikit = require('uikit');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

class RootApp extends _react2.default.Component {

  constructor(props) {
    super(props);
  }

  getChildContext() {
    return {
      configs: this.props.configs,
      viewer: this.props.viewer,
      manifest: this.props.manifest
    };
  }

  render() {
    const { store, Router, theme } = this.props;
    return _react2.default.createElement(
      _reactRedux.Provider,
      { store: store },
      _react2.default.createElement(
        _uikit.Provider,
        { theme: theme },
        _react2.default.createElement(Router, null)
      )
    );
  }
}

RootApp.childContextTypes = {
  configs: _propTypes2.default.object,
  viewer: _propTypes2.default.object,
  manifest: _propTypes2.default.object
};
exports.default = RootApp;