'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactRedux = require('react-redux');

var _reactRouter = require('react-router');

var _matchRoutes = require('./matchRoutes');

var _matchRoutes2 = _interopRequireDefault(_matchRoutes);

var _hoistNonReactStatics = require('hoist-non-react-statics');

var _hoistNonReactStatics2 = _interopRequireDefault(_hoistNonReactStatics);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

exports.default = function (Route, config) {
  var routes = config.routes,
      onUpdate = config.onUpdate;

  var Listener = function (_Component) {
    _inherits(Listener, _Component);

    function Listener(props) {
      _classCallCheck(this, Listener);

      return _possibleConstructorReturn(this, (Listener.__proto__ || Object.getPrototypeOf(Listener)).call(this, props));
    }

    _createClass(Listener, [{
      key: 'componentDidMount',
      value: function componentDidMount() {
        this.fetchRoutes(this.props);
      }
    }, {
      key: 'componentWillReceiveProps',
      value: function componentWillReceiveProps(nextProps) {
        var current = '' + this.props.location.pathname + this.props.location.search;
        var next = '' + nextProps.location.pathname + nextProps.location.search;
        if (current === next) {
          return;
        }
        this.fetchRoutes(nextProps);
      }
    }, {
      key: 'fetchRoutes',
      value: function fetchRoutes(props) {
        var branch = (0, _matchRoutes2.default)(routes, props.location.pathname);
        if (branch.length) {
          onUpdate({
            route: branch[0]['route'],
            match: props.match,
            location: props.location
          });
        }
      }
    }, {
      key: 'render',
      value: function render() {
        return _react2.default.createElement(Route, this.props);
      }
    }]);

    return Listener;
  }(_react.Component);

  return (0, _reactRouter.withRouter)((0, _reactRedux.connect)()((0, _hoistNonReactStatics2.default)(Listener, Route)));
};