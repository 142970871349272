var Cookies = require('cookies-js');
export default {
  saveToken: function saveToken(payload) {
    var resolvedRefreshToken = payload.refreshToken || payload.refresh_token;

    Cookies.set('token', payload.token, { expires: Infinity });
    Cookies.set('refreshToken', resolvedRefreshToken, { expires: Infinity });
  },
  removeToken: function removeToken() {
    Cookies.expire('token');
    Cookies.expire('refreshToken');
  },
  getToken: function getToken() {
    return {
      token: Cookies.get('token'),
      refreshToken: Cookies.get('refreshToken')
    };
  }
};