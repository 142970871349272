'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var CollapsibleContentBase = function CollapsibleContentBase(props) {
  return _react2.default.createElement(
    _.Box,
    props,
    _react2.default.createElement(
      _.Box,
      null,
      props.children
    )
  );
};

exports.default = CollapsibleContentBase;