"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var getCurrentDate = function getCurrentDate() {
  var date = new Date();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  return yyyy + "-" + mm + "-" + dd;
};

exports.getCurrentDate = getCurrentDate;