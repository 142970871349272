'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  position: absolute;\n  opacity: ', ';\n'], ['\n  transition: opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  background-color: rgba(0, 0, 0, 0.3);\n  z-index: 2;\n  position: absolute;\n  opacity: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  position: relative;\n  transform: ', ';\n  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),\n    opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  will-change: transform;\n  opacity: ', ';\n  z-index: 3;\n  height: auto;\n  max-height: ', ';\n  overflow: ', ';\n'], ['\n  position: relative;\n  transform: ', ';\n  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),\n    opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  will-change: transform;\n  opacity: ', ';\n  z-index: 3;\n  height: auto;\n  max-height: ', ';\n  overflow: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: ', ';\n  z-index: 9999;\n'], ['\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  pointer-events: ', ';\n  z-index: 9999;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  transform: ', ';\n  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),\n    opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  will-change: transform;\n  opacity: ', ';\n  height: 100%;\n'], ['\n  transform: ', ';\n  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1),\n    opacity 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);\n  will-change: transform;\n  opacity: ', ';\n  height: 100%;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _gridStyled = require('grid-styled');

var _withToggle = require('./withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withEscape = require('./withEscape');

var _withEscape2 = _interopRequireDefault(_withEscape);

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var StyledOverlay = (0, _styledComponents2.default)(_.Overlay)(_templateObject, function (props) {
  return props.open ? 1 : 0;
});

var StyledCard = (0, _styledComponents2.default)(_.Card)(_templateObject2, function (props) {
  return props.open ? 'none' : 'scale(.85)';
}, function (props) {
  return props.open ? 1 : 0;
}, function (props) {
  return props.overflow === 'auto' ? '100%' : 'auto';
}, function (props) {
  return props.overflow;
});

var StyledFlex = (0, _styledComponents2.default)(_gridStyled.Flex)(_templateObject3, function (props) {
  return props.open ? 'all' : 'none';
});

var StyledFull = (0, _styledComponents2.default)(_gridStyled.Box)(_templateObject4, function (props) {
  return props.open ? 'none' : 'scale(.85)';
}, function (props) {
  return props.open ? 1 : 0;
});

var Modal = function Modal(_ref) {
  var open = _ref.open,
      w = _ref.w,
      children = _ref.children,
      onClickOutside = _ref.onClickOutside,
      onPressEsc = _ref.onPressEsc,
      overflow = _ref.overflow,
      p = _ref.p,
      full = _ref.full,
      style = _ref.style;
  return _react2.default.createElement(
    StyledFlex,
    { align: 'center', justify: 'center', open: open },
    !full && _react2.default.createElement(StyledOverlay, {
      open: open,
      onClick: onClickOutside ? onClickOutside : function () {}
    }),
    !full && _react2.default.createElement(
      StyledCard,
      {
        open: open,
        w: w,
        p: p || 3,
        overflow: overflow || 'hidden',
        style: style },
      children
    ),
    full && _react2.default.createElement(
      StyledFull,
      { open: open, bg: 'white', w: 1 },
      children
    )
  );
};

exports.default = (0, _withEscape2.default)(Modal);