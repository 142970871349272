'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  min-height: 20px;\n  height: auto;\n  border-radius: 4px;\n  border: solid 1px ', ';\n'], ['\n  min-height: 20px;\n  height: auto;\n  border-radius: 4px;\n  border: solid 1px ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  border-radius: 4px;\n'], ['\n  border-radius: 4px;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  height: 140px;\n  overflow: auto;\n  border-radius: none;\n  box-sizing: border-box;\n  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);\n'], ['\n  height: 140px;\n  overflow: auto;\n  border-radius: none;\n  box-sizing: border-box;\n  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  color: black;\n  text-align: left;\n  text-transform: capitalize;\n  padding: 10px;\n  font-weight: normal;\n  border-bottom: solid 1px ', ';\n  border-radius: none !important;\n\n  &:hover {\n    color: ', ';\n  }\n'], ['\n  color: black;\n  text-align: left;\n  text-transform: capitalize;\n  padding: 10px;\n  font-weight: normal;\n  border-bottom: solid 1px ', ';\n  border-radius: none !important;\n\n  &:hover {\n    color: ', ';\n  }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  padding: 10px;\n  border-left: solid 1px ', ';\n  height: auto;\n'], ['\n  padding: 10px;\n  border-left: solid 1px ', ';\n  height: auto;\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  z-index: 200;\n  cursor: pointer;\n'], ['\n  z-index: 200;\n  cursor: pointer;\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  color: ', ';\n  position: absolute;\n  right: 20px;\n'], ['\n  color: ', ';\n  position: absolute;\n  right: 20px;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rgIcons = require('rg-icons');

var _recompose = require('recompose');

var _withToggle = require('./withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('./withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var INIT_OPTIONS = [{
  label: 'Select All',
  value: '',
  isSelected: false
}];

var MultiSelect = function (_React$Component) {
  _inherits(MultiSelect, _React$Component);

  function MultiSelect(props) {
    _classCallCheck(this, MultiSelect);

    var _this = _possibleConstructorReturn(this, (MultiSelect.__proto__ || Object.getPrototypeOf(MultiSelect)).call(this, props));

    _this._setSelectStatus = function (value, isSelected) {
      var _this$state = _this.state,
          options = _this$state.options,
          selectedOptions = _this$state.selectedOptions;


      var newOptions = Array.isArray(options) && options.map(function (option, index) {
        if (value !== '') {
          if (option.value === value) {
            return {
              value: option.value,
              label: option.label,
              isSelected: isSelected
            };
          } else if (option.value === '') {
            return {
              value: option.value,
              label: Array.isArray(selectedOptions) && selectedOptions.length < options.length ? 'Select All' : 'Deselect All',
              isSelected: false
            };
          } else {
            return {
              value: option.value,
              label: option.label,
              isSelected: option.isSelected
            };
          }
        } else {
          return {
            value: option.value,
            label: index === 0 ? isSelected ? 'Deselect All' : 'Select All' : option.label,
            isSelected: isSelected
          };
        }
      }) || [];

      _this.setState({ options: newOptions });
    };

    _this._onClick = function () {
      var _this$props = _this.props,
          open = _this$props.open,
          setOpen = _this$props.setOpen;

      setOpen(!open);
    };

    _this._onSelect = function (value) {
      var _this$props2 = _this.props,
          propOptions = _this$props2.options,
          setFieldValue = _this$props2.setFieldValue;
      var _this$state2 = _this.state,
          options = _this$state2.options,
          selectedOptions = _this$state2.selectedOptions;

      var isSelected = false;
      var newSelectedOptions = [].concat(_toConsumableArray(selectedOptions));

      if (value !== '') {
        if (newSelectedOptions.includes(value)) {
          isSelected = false;
          newSelectedOptions = selectedOptions.slice(0, selectedOptions.indexOf(value)).concat(selectedOptions.slice(selectedOptions.indexOf(value) + 1));
        } else {
          isSelected = true;
          newSelectedOptions.push(value);
        }
      } else {
        if (newSelectedOptions.length === options.length - 1) {
          isSelected = false;
          newSelectedOptions = [];
        } else {
          isSelected = true;
          newSelectedOptions = propOptions.map(function (option) {
            return option.value;
          });
        }
      }

      _this.setState({ selectedOptions: newSelectedOptions }, function () {
        _this._setSelectStatus(value, isSelected);
        setFieldValue('tagMulti', newSelectedOptions);
        setFieldValue('tags', newSelectedOptions.join(','));
      });
    };

    _this._onRemoveOption = function (value) {
      var setFieldValue = _this.props.setFieldValue;
      var selectedOptions = _this.state.selectedOptions;

      var isSelected = false;

      if (selectedOptions.includes(value)) {
        var newSelectedOptions = selectedOptions.slice(0, selectedOptions.indexOf(value)).concat(selectedOptions.slice(selectedOptions.indexOf(value) + 1));

        _this.setState({ selectedOptions: newSelectedOptions }, function () {
          _this._setSelectStatus(value, isSelected);
          setFieldValue('tagMulti', newSelectedOptions);
          setFieldValue('tags', newSelectedOptions.join(','));
        });
      }
    };

    _this._renderOptionsBox = function () {
      var options = _this.state.options;


      return React.createElement(
        StyledOptionsBox,
        { bg: 'white', is: _.Absolute, w: 1 },
        Array.isArray(options) && options.map(function (item, index) {
          return React.createElement(
            StyledOptionItemButton,
            {
              w: 1,
              bg: 'white',
              value: item.value,
              type: 'button',
              key: item.value,
              onClick: function onClick(e) {
                return _this._onSelect(e.target.value);
              } },
            item.label,
            item.isSelected && React.createElement(StyledCheckedOptionIcon, { name: 'checked' })
          );
        })
      );
    };

    _this._renderNoSelectedOptions = function () {
      var placeholder = _this.props.placeholder;


      return React.createElement(
        _.Text,
        { mt: '3px', color: 'grey' },
        placeholder
      );
    };

    _this._renderSelectedOptions = function () {
      var options = _this.state.options;


      return React.createElement(
        _.Flex,
        { flexWrap: 'wrap' },
        Array.isArray(options) && options.map(function (item, index) {
          var isSelected = item.isSelected;


          if (isSelected && index !== 0) {
            var currentKey = '' + (item.value ? String(item.value) : '') + String(index);
            return React.createElement(
              StyledSelectedOptionsBox,
              {
                key: currentKey,
                bg: _.theme.colors.blue,
                color: _.theme.colors.white,
                p: '10px',
                mr: '5px',
                mb: '5px' },
              React.createElement(
                _.Flex,
                { justifyContent: 'space-between', alignItems: 'flex-end' },
                React.createElement(
                  _.Box,
                  { mr: '10px' },
                  item.label
                ),
                React.createElement(StyledRemoveIcon, {
                  onClick: function onClick() {
                    return item.value && _this._onRemoveOption(item.value);
                  },
                  name: 'x',
                  size: '12px'
                })
              )
            );
          } else {
            return null;
          }
        })
      );
    };

    _this._onClickOutside = function () {
      _this.props.setOpen(false);
    };

    _this.state = {
      options: INIT_OPTIONS,
      selectedOptions: []
    };
    return _this;
  }

  _createClass(MultiSelect, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          options = _props.options,
          values = _props.values;


      var newOptions = [].concat(INIT_OPTIONS, _toConsumableArray(options.map(function (item) {
        return {
          label: item.label,
          value: item.value,
          isSelected: values.some(function (value) {
            return value === item.value;
          })
        };
      })));

      this.setState({ options: newOptions, selectedOptions: values });
    }
  }, {
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var prevOptions = prevProps.options;
      var _props2 = this.props,
          options = _props2.options,
          values = _props2.values;


      var isOptionsChanged = prevOptions !== options;

      if (isOptionsChanged) {
        var newOptions = [].concat(INIT_OPTIONS, _toConsumableArray(options.map(function (item) {
          return {
            label: item.label,
            value: item.value,
            isSelected: values.some(function (value) {
              return value === item.value;
            })
          };
        })));

        this.setState({ options: newOptions, selectedOptions: values });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var open = this.props.open;
      var _state = this.state,
          options = _state.options,
          selectedOptions = _state.selectedOptions;

      var isOptionsSelected = Array.isArray(options) && Array.isArray(selectedOptions) && selectedOptions.length > 0;

      return React.createElement(
        'div',
        { ref: this.props.onRef },
        React.createElement(
          _.Box,
          { is: _.Relative, w: 1 },
          React.createElement(
            StyledDropDownBox,
            { is: _.Relative, w: 1, onClick: this._onClick },
            React.createElement(
              _.Flex,
              { justifyContent: 'space-between', alignItems: 'flex-start' },
              React.createElement(
                _.Box,
                { pl: '5px', pt: '5px' },
                !isOptionsSelected && this._renderNoSelectedOptions(),
                isOptionsSelected && this._renderSelectedOptions()
              ),
              React.createElement(
                StyledChevronBox,
                null,
                open && React.createElement(_rgIcons.Icon, { name: 'triangleUp', color: 'grey', size: 10 }),
                !open && React.createElement(_rgIcons.Icon, { name: 'triangleDown', color: 'gey', size: 10 })
              )
            )
          ),
          open && this._renderOptionsBox()
        )
      );
    }
  }]);

  return MultiSelect;
}(React.Component);

var StyledDropDownBox = (0, _styledComponents2.default)(_.Box)(_templateObject, function (props) {
  return props.theme.colors.lightGrey;
});

var StyledSelectedOptionsBox = (0, _styledComponents2.default)(_.Box)(_templateObject2);

var StyledOptionsBox = (0, _styledComponents2.default)(_.Box)(_templateObject3);

var StyledOptionItemButton = (0, _styledComponents2.default)(_.Button)(_templateObject4, function (props) {
  return props.theme.colors.lightGrey;
}, function (props) {
  return props.theme.colors.blue;
});

var StyledChevronBox = (0, _styledComponents2.default)(_.Box)(_templateObject5, function (props) {
  return props.theme.colors.lightGrey;
});

var StyledRemoveIcon = (0, _styledComponents2.default)(_rgIcons.Icon)(_templateObject6);

var StyledCheckedOptionIcon = (0, _styledComponents2.default)(_rgIcons.Icon)(_templateObject7, function (props) {
  return props.theme.colors.blue;
});

exports.default = (0, _recompose.compose)(_withToggle2.default, (0, _recompose.withProps)(function (_ref) {
  var setOpen = _ref.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  };
}), _withClickOutside2.default)(MultiSelect);