'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = undefined;

var _constants = require('./constants');

var formatDate = function formatDate(val) {
  var date = new Date(val);

  var dd = date.getDate();
  var mm = date.getMonth();
  var yyyy = date.getFullYear();

  return dd + ' ' + _constants.MONTHS_LIST.full[mm] + ' ' + yyyy;
};

exports.formatDate = formatDate;