'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _propTypes = require('prop-types');

var _theme = require('../theme');

var _util = require('../util');

var _Modal = require('./Modal');

var _Modal2 = _interopRequireDefault(_Modal);

var _Portal = require('./Portal');

var _Portal2 = _interopRequireDefault(_Portal);

var _DropdownBase = require('./DropdownBase');

var _DropdownBase2 = _interopRequireDefault(_DropdownBase);

var _Popover = require('./Popover');

var _Popover2 = _interopRequireDefault(_Popover);

var _Collapsible = require('./Collapsible');

var _Collapsible2 = _interopRequireDefault(_Collapsible);

var _CollapsibleContentBase = require('./CollapsibleContentBase');

var _CollapsibleContentBase2 = _interopRequireDefault(_CollapsibleContentBase);

var _MultiSelect = require('./MultiSelect');

var _MultiSelect2 = _interopRequireDefault(_MultiSelect);

var _AutoComplete = require('./AutoComplete');

var _AutoComplete2 = _interopRequireDefault(_AutoComplete);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var unstableComponents = [{
  name: 'Modal',
  type: _Modal2.default,
  props: {},
  style: function style(props) {
    return {};
  }
}, {
  name: 'MultiSelect',
  type: _MultiSelect2.default,
  props: {},
  style: function style(props) {
    return {};
  }
}, {
  name: 'AutoComplete',
  type: _AutoComplete2.default,
  props: {},
  style: function style(props) {
    return {};
  }
}, {
  name: 'Portal',
  type: _Portal2.default,
  props: {},
  style: function style(props) {
    return {};
  }
}, {
  name: 'Dropdown',
  type: _DropdownBase2.default,
  props: {},
  style: function style(props) {
    return {
      position: 'relative'
    };
  }
}, {
  name: 'Collapsible',
  type: _Collapsible2.default,
  props: {},
  style: function style(props) {
    return {
      position: 'relative'
    };
  }
}, {
  name: 'CollapsibleContent',
  type: _CollapsibleContentBase2.default,
  props: {
    f: 0
  },
  style: function style(props) {
    return {
      display: props.open ? 'block' : 'none',
      position: 'relative',
      pointerEvents: props.open ? 'all' : 'none'
    };
  }
}, {
  name: 'Popover',
  type: _Popover2.default,
  props: {
    f: 0,
    bg: 'white'
  },
  style: function style(props) {
    return {
      borderRadius: (0, _util.px)(props.theme.radius),
      boxShadow: '0 0 0 1px rgba(136,152,170,.1), 0 15px 35px 0 rgba(49,49,93,.1), 0 5px 15px 0 rgba(0,0,0,.08)',
      position: 'absolute',
      bottom: 0,
      transform: 'translateY(calc(100% + 10px))',
      pointerEvents: props.open ? 'all' : 'none',
      opacity: props.open ? '1' : '0',
      transition: 'opacity 0.08s ease-out, transform 0.08s ease-out',
      transitionDuration: '0.12',
      transitionDelay: '0.1s'
    };
  }
}, {
  name: 'Carousel',
  type: 'div',
  props: {},
  style: function style(props) {
    return {
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '& > div:first-child': {
        marginLeft: props.index * -100 + '%',
        transitionProperty: 'margin',
        transitionDuration: '.2s',
        transitionTimingFunction: 'ease-out'
      }
    };
  },
  propTypes: {
    index: _propTypes.number
  }
}, {
  name: 'CarouselSlide',
  type: 'div',
  props: {
    w: 1,
    p: 3
  },
  style: function style(props) {
    return {
      display: 'inline-block',
      verticalAlign: 'middle'
    };
  }
}];

exports.default = unstableComponents;