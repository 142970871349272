'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ensureString = function ensureString(str, defaultValue) {
  if (!defaultValue) {
    return typeof str === 'string' && str || '';
  }

  return typeof str === 'string' && str || ensureString(defaultValue);
};

exports.default = ensureString;