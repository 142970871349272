'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteral(['\n  position: relative;\n  display: inline-block;\n'], ['\n  position: relative;\n  display: inline-block;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _withToggle = require('./withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('./withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var DropdownContainer = _styledComponents2.default.div(_templateObject);

var Dropdown = function Dropdown(props) {
  return _react2.default.createElement(
    DropdownContainer,
    _extends({}, props, { innerRef: props.onRef }),
    _react2.default.cloneElement(props.children[0], {
      onClick: props.toggle,
      open: props.open
    }),
    _react2.default.cloneElement(props.children[1], { open: props.open })
  );
};

exports.default = (0, _recompose.compose)(_withToggle2.default, (0, _recompose.withProps)(function (_ref) {
  var setOpen = _ref.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  };
}), _withClickOutside2.default)(Dropdown);