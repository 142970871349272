'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  position: relative;\n  display: inline-block;\n'], ['\n  position: relative;\n  display: inline-block;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _withToggle = require('./withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('./withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _recompose = require('recompose');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Collapsible = function Collapsible(_ref) {
  var open = _ref.open,
      toggle = _ref.toggle,
      children = _ref.children,
      initOpen = _ref.initOpen;
  return _react2.default.createElement(
    CollapsibleContainer,
    null,
    _react2.default.cloneElement(children[0], {
      onClick: toggle,
      open: initOpen ? !open : open
    }),
    _react2.default.cloneElement(children[1], { open: initOpen ? !open : open })
  );
};

var CollapsibleContainer = _styledComponents2.default.div(_templateObject);

exports.default = (0, _withToggle2.default)(Collapsible);