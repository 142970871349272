'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var formatDurationAsString = function formatDurationAsString(_ref) {
  var durationAsObject = _ref.durationAsObject,
      _ref$capitalizeString = _ref.capitalizeString,
      capitalizeString = _ref$capitalizeString === undefined ? true : _ref$capitalizeString;
  var hours = durationAsObject.hours,
      minutes = durationAsObject.minutes;


  var hoursAsNumber = typeof hours === 'number' ? hours : parseInt(durationAsObject.hours, 10);
  var minutesAsNumber = typeof minutes === 'number' ? minutes : parseInt(durationAsObject.minutes, 10);

  var hoursNotation = hoursAsNumber === 1 ? 'Hour' : 'Hours';
  var minutesNotation = minutesAsNumber === 1 ? 'Minute' : 'Minutes';

  var formattedHoursNotation = capitalizeString ? hoursNotation : hoursNotation.toLowerCase();

  var formattedMinutesNotation = capitalizeString ? minutesNotation : minutesNotation.toLowerCase();

  var formattedDurationAsString = hoursAsNumber && minutesAsNumber ? hoursAsNumber + ' ' + formattedHoursNotation + ' ' + minutesAsNumber + ' ' + formattedMinutesNotation : hoursAsNumber && !minutesAsNumber ? hoursAsNumber + ' ' + formattedHoursNotation : minutesAsNumber + ' ' + formattedMinutesNotation;

  return formattedDurationAsString;
};

exports.formatDurationAsString = formatDurationAsString;