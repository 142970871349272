'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statusColorFocus = exports.statusColor = exports.align = exports.alignValue = exports.caps = exports.lighten = exports.darken = exports.color = exports.px = exports.idx = undefined;

var _theme = require('./theme');

// replace with get?
var idx = exports.idx = function idx(props, obj) {
  var keys = typeof props === 'string' ? props.split('.') : props;
  return keys.reduce(function (a, b) {
    return a && a[b] ? a[b] : null;
  }, obj);
};

var px = exports.px = function px(n) {
  return typeof n === 'number' ? n + 'px' : n;
};

var color = exports.color = function color(props) {
  return function () {
    var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _theme.colors.primary;
    return idx(['colors', n], props.theme) || n;
  };
};

var darken = exports.darken = function darken(n) {
  return 'rgba(0, 0, 0, ' + n + ')';
};

var lighten = exports.lighten = function lighten(n) {
  return 'rgba(255, 255, 255, ' + n + ')';
};

var caps = exports.caps = function caps(props) {
  return props.caps ? {
    textTransform: 'uppercase',
    letterSpacing: '.2em'
  } : {};
};

var alignValue = exports.alignValue = function alignValue(props) {
  if (props.left) return 'left';
  if (props.center) return 'center';
  if (props.right) return 'right';
  if (props.justify) return 'justify';
  return null;
};

var align = exports.align = function align(props) {
  var value = alignValue(props);
  if (!value) return null;
  return {
    textAlign: value
  };
};

var statusColor = exports.statusColor = function statusColor(props) {
  var default_c = color(props)('border');
  var danger_c = color(props)('danger');
  var valid_c = color(props)('valid');
  var warn_c = color(props)('warn');

  switch (props.status) {
    case 'error':
      return danger_c;
    case 'valid':
      return valid_c;
    case 'warn':
      return warn_c;
    default:
      return default_c;
  }
};

var statusColorFocus = exports.statusColorFocus = function statusColorFocus(props) {
  switch (props.status) {
    case 'error':
      return color(props)('red');
    case 'valid':
      return color(props)('primary');
    case 'warn':
      return color(props)('orange');
    default:
      return color(props)('primary');
  }
};

exports.default = {
  idx: idx,
  px: px,
  darken: darken,
  caps: caps,
  align: align,
  color: color,
  statusColor: statusColor,
  statusColorFocus: statusColorFocus
};