'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _reactDom = require('react-dom');

var _apiClient = require('api-client');

var _routerUtils = require('router-utils');

var _ManifestWrapper = require('./ManifestWrapper');

var _ManifestWrapper2 = _interopRequireDefault(_ManifestWrapper);

var _reactHotLoader = require('react-hot-loader');

var _rukerAdminAuth = require('ruker-admin-auth');

var _RootApp = require('./RootApp');

var _RootApp2 = _interopRequireDefault(_RootApp);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// TODO: fix this! It is really weird!
const fetchViewerContext = async (apiClient, configs, token = '', refreshToken = '') => {
  let appGroup = configs.appGroup ? configs.appGroup : 'ruangkerja';
  let payload = {
    token: token,
    refreshToken: refreshToken,
    clientId: appGroup === 'ruangguru' ? configs.clientId : configs.rukerClientId,
    clientSecret: appGroup === 'ruangguru' ? configs.clientSecret : configs.rukerClientSecret
  };

  let client;

  switch (appGroup) {
    case 'rg-admin':
      client = apiClient.post(`api/refresh-token`).then(res => {
        const client = {
          deviceId: '',
          deviceName: '',
          isLoggedIn: true,
          refreshToken: res[0].refresh_token,
          token: res[0].token,
          userSerial: res[0].unique_order_code
        };
        return client;
      });
      break;
    case 'ruangguru':
      client = apiClient.post(`api/v3/user/viewer_context`, payload);
      break;
    case 'noAuth':
      client = {
        deviceId: '',
        deviceName: '',
        isLoggedIn: false,
        refreshToken: '',
        token: '',
        userSerial: ''
      };
      break;
    case 'skillacademy':
      client = apiClient.get(`skillacademy/viewer_context`, payload);
      break;
    case 'ruangkerja':
      client = apiClient.get(`ruangkerja/viewer_context`, payload);
      break;
    default:
      client = apiClient.get(`ruangkerja/viewer_context`, payload);
  }

  try {
    let response = await client;
    if (!response) {
      throw new Error('API Bad response');
    }
    _rukerAdminAuth.authUtils.saveToken(response);
    return response;
  } catch (e) {
    return Promise.resolve({
      isLoggedIn: false
    });
  }
};

// Todo: make sure flow coverage to 100%
const createClient = options => {
  const {
    routes,
    assets,
    theme,
    createStore,
    apiMockData,
    handleRouteChanged
  } = options;

  const configs = window._CONFIGS;

  let renderApp;

  const { token, refreshToken } = _rukerAdminAuth.authUtils.getToken();

  const apiClient = new _apiClient.ApiClient(_extends({}, configs, {
    token: token,
    onAuthError: async error => {
      const { config } = error;
      const response = await fetchViewerContext(apiClient, configs, token, refreshToken);

      if (response.token) {
        apiClient.setToken(response.token);
      }

      return apiClient.request(config);
    }
  }), apiMockData);

  fetchViewerContext(apiClient, configs, token, refreshToken).then(viewerContext => {
    const store = createStore({ apiClient });

    const manifest = new _ManifestWrapper2.default(configs, assets);

    renderApp = routes => {
      const Router = (0, _routerUtils.routerCreator)(routes, viewerContext.isLoggedIn);
      Router.onStateChanged(({ route, location, match }) => {
        const { component } = route;
        const { fetchInitialData } = component;

        const context = { viewer: viewerContext, configs };

        if (typeof handleRouteChanged === 'function') {
          handleRouteChanged({
            route,
            location,
            match,
            context
          });
        }

        const fetchInitialDataResolver = fetchInitialData && fetchInitialData({
          location,
          match,
          context
        });

        if (fetchInitialDataResolver && fetchInitialDataResolver instanceof Promise) {
          fetchInitialDataResolver.then(() => {
            // Do stuff after prefetch
          }).catch(err => {
            // eslint-disable-next-line no-console
            console.error(err);
          });
        }
      });

      const root = document.getElementById('root');

      if (root == null) {
        throw new Error('No element with id = root');
      }

      (0, _reactDom.render)(_react2.default.createElement(
        _reactHotLoader.AppContainer,
        null,
        _react2.default.createElement(_RootApp2.default, {
          theme: theme,
          store: store,
          manifest: manifest,
          viewer: viewerContext,
          configs: configs,
          Router: Router
        })
      ), root);
    };

    renderApp(routes);
  });

  return {
    rerender: routes => {
      renderApp(routes);
    }
  };
};

exports.default = createClient;